import React from "react"
import Link from "gatsby-link"
import "./nav.css"


export default () => (
<section id="nav">
    <div className="nav" style={{ display: "flex", flex: 1, position: "fixed", top: "0"  }}>
        <input type="checkbox" id="nav-check" />
        <div className="nav-header">
            <div className="nav-title">
                <Link to="/">JimTheFish</Link>
            </div>
        </div>
        <div className="nav-btn">
            <label htmlFor="nav-check">
            <span></span>
            <span></span>
            <span></span>
            </label>
        </div>

        <div className="nav-links">
            <Link to="/page-2">Hello</Link>
            <Link to="/blog">Blog</Link> 
            <Link to="/page-css">CSS</Link>
            <Link to="/page-test">External</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/search">Search</Link>
        </div>
    </div>
</section>
)