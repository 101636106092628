import React from "react"
import "./layout.css"
import { CookiesProvider } from 'react-cookie'
import Nav from "../components/nav"
import Container from "../components/container"
import Footer from "../components/footer"

export default ({ children }) => (
  <>
    {/* <CookiesProvider> */}
    <div className="page">
      <div className="page-container">
        <Nav />
        <Container>{children}</Container>
      </div>
      <Footer />
    </div>
    {/* </CookiesProvider> */}
  </>
)