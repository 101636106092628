import React, { Component } from "react"
import Link from "gatsby-link"
import "./footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import CookiePreferences from "../components/cookiePreferences"
// import CookiePreferences from "../components/cookieContainerBottom"

class Footer extends Component {
  componentDidMount() {
//    const script = document.createElement("script")
//    script.src = '//www.privacypolicies.com/public/cookie-consent/3.1.0/cookie-consent.js'
//    document.head.appendChild(script)

//    document.addEventListener("DOMContentLoaded", function() {
//      cookieconsent.run({
//        notice_banner_type: "interstitial",
//        consent_type: "express",
//        palette: "light",
//        language: "en",
//        website_name: "Hello World",
//        change_preferences_selector: "#changePreferences",
//      })
//    })
  }
  render() {
    return (
      <section id="footer" className="bg-footer-black">
        <div className="container grid-xl">
          <div className="frame-box">
            <div className="columns foot-style">
              <div className="column col-3 col-md-3 col-sm-12">
                <div className="footer-col">
                  <h5>Our solutions</h5>
                  <p>
                    <i className="fa fa-angle-right"></i>
                    <Link to="/blog">Blog</Link>
                  </p>
                </div>
                <div className="space-10"></div>
              </div>

              <div className="column col-3 col-md-3 col-sm-12">
                <div className="footer-col">
                  <h5>Useful links</h5>
                  <p>
                    <i className="fa fa-angle-right"></i>
                    <Link to="/">Home</Link>
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i>
                    <Link to="/search">Search</Link>
                  </p>
                </div>
                <div className="space-10"></div>
              </div>

              <div className="column col-3 col-md-3 col-sm-12">
                <div className="footer-col">
                  <h5>Sitemap &amp; Legal</h5>
                  <p>
                    <i className="fa fa-angle-right"></i>
                    <Link to="/sitemap">Sitemap</Link>
                  </p>
                  {/* <CookiePreferences>
                    <span style={{ fontSize: "10px" }}>Cookie Preferences</span>
                  </CookiePreferences> */}
                  {/*<p>
                    <a id="changePreferences">Cookie Preferences 2</a>
                  </p>*/}
                  {/* <a href="javascript:CookieScript.instance.show()" style={{fontSize: "0.7rem", fontWeight: "300"}}>Cookie preferences</a> */}
                </div>
                <div className="space-10"></div>
              </div>

              <div className="column col-3 col-md-3 col-sm-12">
                <div className="footer-col">
                  <h5>Social Media</h5>
                  <ul className="list-inline social-btn">
                    <li>
                      <a
                        id="my-linkedin"
                        href="http://www.linkedin.com/company/#"
                      >
                        <FontAwesomeIcon
                          icon={["fab", "linkedin-in"]}
                          size="2x"
                        />
                      </a>
                    </li>
                    <li>
                      <a id="my-twitter" href="http://www.twitter.com/#">
                        <FontAwesomeIcon icon={["fab", "twitter"]} size="2x" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="columns foot-btm">
              <div className="column col-12">
                <p>© {new Date().getFullYear()} JimTheFish Incorporated!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Footer
